import styles from './HistorySection.module.scss';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import React from 'react';
import Image from 'next/image';
import Fundamentals from '../../../../../assets/img/home/dark/history/fundamentals.png';
import Classic from '../../../../../assets/img/home/dark/history/classic.png';
import Tradition from '../../../../../assets/img/home/dark/history/tradition.png';

export const HistorySection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';

    return (
        <section className={`${styles.HistoryContainer}`} ref={ref}>
            <div className={styles.HistoryContent}>
                <div className={`${styles.TopContainer} ${runAnimation}`}>
                    <h2 className={`${styles.TopTitle} ${runAnimation}`}>History &amp; Legacy</h2>
                    <p className={`${styles.TopDescription} ${runAnimation}`}>
                        Fine wine and whiskey have symbolized financial success for centuries—not just as luxury goods,
                        but as a way to build wealth. They&apos;re the rare investment assets that reward patience,
                        curation, and good taste alike.
                    </p>
                </div>
                <div className={styles.rowContainer}>
                    <div className={'text'}>
                        <h3 className={`${styles.SectionTitle} ${runAnimation}`}>Time-tested fundamentals</h3>
                        <p className={`${styles.SectionDescription} ${runAnimation}`}>
                            Both wine and whiskey improve with age, get scarcer over time, and historically stay stable
                            through market fluctuations.
                        </p>
                    </div>
                    <div className={'image'}>
                        <Image src={Fundamentals} alt={'Time-tested fundamentals'} />
                    </div>
                </div>
                <div className={styles.rowContainer}>
                    <div className={'image'}>
                        <Image src={Classic} alt={'A classic choice'} />
                    </div>
                    <div className={'text pl'}>
                        <h3 className={`${styles.SectionTitle} ${runAnimation}`}>A classic choice</h3>
                        <p className={`${styles.SectionDescription} ${runAnimation}`}>
                            Wine and whiskey are as timeless as gold and diamonds—yet have performed better than both
                            over the past 100 years.
                        </p>
                    </div>
                </div>
                <div className={styles.rowContainer}>
                    <div className={'text'}>
                        <h3 className={`${styles.SectionTitle} ${runAnimation}`}>Tradition and value</h3>
                        <p className={`${styles.SectionDescription} ${runAnimation}`}>
                            Wine and whiskey investors aren't just putting money in a portfolio; they're sustaining a
                            culture of craftsmanship and heritage. When you diversify into wine and whiskey, you're not
                            just joining a rich legacy—you're building your own.
                        </p>
                    </div>
                    <div className={'image'}>
                        <Image src={Tradition} alt={'Tradition and value'} />
                    </div>
                </div>
            </div>
        </section>
    );
};
