import React from 'react';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import styles from './GetStarted.module.scss';
import { Button } from '~/vinovest-design-system/components';
import { baseRoutes } from '~/routes/base';

export const GetStarted = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';

    return (
        <div className={styles.Wrapper} ref={ref}>
            <div className={`${styles.Container} ${runAnimation}`}>
                <h2>Grow your wealth with wine and whiskey</h2>
                <Button
                    variant={'green'}
                    href={baseRoutes.signup.href}
                    font={'font-vinovest-medium'}
                    classnames={'text-16 !capitalize'}
                >
                    Get Started
                </Button>
            </div>
        </div>
    );
};
