import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { GetServerSideProps } from 'next';
import { getCookieAuthentication } from '~/utils/getCookieAuthentication';
import { PosthogHelper } from '~/helpers/PosthogHelper';
import { HomePageDark } from '~/components/views/Home/HomePageDark';

const AppContainer = dynamic(() => import('~/containers/AppContainer/AppContainer'));

const Home: React.FC = () => {
    useEffect(() => {
        PosthogHelper.capture('Homepage-nextjs', { bucket: 'test' });
    });

    return (
        <AppContainer>
            <Head>
                <title>Vinovest | Wine Investment</title>
                <meta
                    name={'description'}
                    content={
                        'With 13.6% annualized returns over the past 15 years, fine wine has outperformed most global equities and is less volatile than real estate or gold.'
                    }
                />
                <meta
                    property={'og:description'}
                    content={
                        'With 13.6% annualized returns over the past 15 years, fine wine has outperformed most global equities and is less volatile than real estate or gold.'
                    }
                />
                <link rel={'canonical'} href={`https://www.vinovest.co`} />
            </Head>
            <HomePageDark />
        </AppContainer>
    );
};

// eslint-disable-next-line @typescript-eslint/require-await
export const getServerSideProps: GetServerSideProps = async ({ req }) => {
    const isClientNav = Boolean(req.headers['x-nextjs-data']);

    const authenticationParams = getCookieAuthentication(req);
    if (!isClientNav && authenticationParams.userId) {
        return {
            redirect: {
                destination: '/dashboard',
                permanent: false
            }
        };
    }

    return { props: {} };
};

export default Home;
