import styles from './ProductsBenefitsSection.module.scss';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import BenefitsChart from '../../../../../assets/img/home/dark/benefits/benefits-chart.png';
import React from 'react';
import Image from 'next/image';

export const ProductsBenefitsSection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';

    return (
        <section className={`${styles.ProductsBenefitsContainer}`} ref={ref}>
            <div className={styles.ProductsBenefitsContent}>
                <div className={`${styles.TopContentContainer} ${runAnimation}`}>
                    <h2 className={`${styles.TopTitle} ${runAnimation}`}>Products and Benefits</h2>
                    <p className={`${styles.TopDescription} ${runAnimation}`}>
                        We&apos;ve built a modern investing platform that allows today&apos;s investors to leverage wine
                        and whiskey as part of their wealth-building strategy.
                    </p>
                    <Image src={BenefitsChart} alt={'Benefits Chart'} />
                </div>
                <ol className={styles.BenefitsList}>
                    <li>
                        <span className={'number'}>01</span>
                        <span className={'title'}>Personalized Portfolio Creation</span>
                        <p>
                            Simply share your investing goals and preferences with us and our experts will craft the
                            perfect wine and whiskey portfolio tailored to your needs.
                        </p>
                    </li>
                    <li>
                        <span className={'number'}>02</span>
                        <span className={'title'}>Hassle-Free Management</span>
                        <p>
                            There's no climate control to manage, no merchants to haggle with, no insurance to buy—just
                            tailored wine and whiskey holdings, expertly handled, stored, and traded by your personal
                            Vinovest team.
                        </p>
                    </li>
                    <li>
                        <span className={'number'}>03</span>
                        <span className={'title'}>Optimized Sale Process</span>
                        <p>
                            When your wine and whiskey reach maturity in 4 to 10 years, we coordinate with premium
                            buyers to sell for the maximal return—which we send on to you.
                        </p>
                    </li>
                    <li>
                        <span className={'number'}>04</span>
                        <span className={'title'}>Ownership Flexibility</span>
                        <p>
                            Best of all, you own your wine holdings 100%. We'll even ship your bottles to your doorstep
                            if you'd like to drink them.
                        </p>
                    </li>
                </ol>
            </div>
        </section>
    );
};
