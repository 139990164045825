import styles from './TrackRecordSection.module.scss';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import React from 'react';
import Slider from 'react-slick';
import Image from 'next/image';
import Dominus from '../../../../../assets/img/home/dark/trackrecord/Dominus.png';
import Domaine from '../../../../../assets/img/home/dark/trackrecord/Domaine.png';
import Dom from '../../../../../assets/img/home/dark/trackrecord/Dom.png';
import Rye from '../../../../../assets/img/home/dark/trackrecord/Rye.png';
import Bourbon from '../../../../../assets/img/home/dark/trackrecord/Bourbon.png';

export const trackrecordSliderData = [
    {
        id: 0,
        src: Dominus,
        title: 'Dominus, Napa Valley 2011',
        returns: '30.6%',
        entry: '10 years',
        exit: '12 years'
    },
    {
        id: 1,
        src: Rye,
        title: 'High-Rye Whiskey (Batch 1)',
        returns: '52.6%',
        exit: '$1,850'
    },
    {
        id: 2,
        src: Domaine,
        title: 'Domaine Anne-Francoise Gros, Beaune Premier Cru, Les Boucherottes 2017',
        returns: '43.1%',
        entry: '3 years',
        exit: '6 years'
    },
    {
        id: 3,
        src: Rye,
        title: 'High-Rye Whiskey (Batch 2)',
        returns: '52.6%',
        exit: '$1,850'
    },
    {
        id: 4,
        src: Dom,
        title: 'Dom Perignon, Luminous 2010',
        returns: '30.7%',
        entry: '11 years',
        exit: '13 years'
    },
    {
        id: 5,
        src: Bourbon,
        title: 'American Bourbon',
        returns: '23.3%',
        exit: '$1,850'
    }
];

export const TrackRecordSection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';

    return (
        <section className={`${styles.TrackRecordContainer}`} ref={ref}>
            <div className={styles.TrackRecordContent}>
                <div className={`${styles.GraphColumnContainer} ${runAnimation}`}>
                    <div className={`${styles.GraphColumn} ${styles.text}`}>
                        <h2 className={`${styles.TopTitle} ${runAnimation}`}>Track Record</h2>
                        <p className={`${styles.TopDescription} ${runAnimation}`}>
                            As assets, wine and whiskey are historical high performers.
                        </p>
                    </div>
                </div>
                <div className={`${styles.SlickWrapper} ${runAnimation} ${styles.row3}`}>
                    <div className={`${styles.full}`}>
                        <Slider
                            className={`${styles.TrackrecordSliderContainer}`}
                            accessibility
                            autoplay={false}
                            slidesToShow={2}
                            slidesToScroll={2}
                            arrows
                            dots
                            responsive={[
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                        infinite: true,
                                        dots: true
                                    }
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                        infinite: false,
                                        centerMode: false,
                                        arrows: false,
                                        dots: true
                                    }
                                },
                                {
                                    breakpoint: 430,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        infinite: false,
                                        centerMode: false,
                                        arrows: false,
                                        dots: true
                                    }
                                }
                            ]}
                        >
                            {trackrecordSliderData.map(({ id, src, title, returns, entry, exit }) => (
                                <div key={id} className={`${styles.SlideWrapper}`}>
                                    <div className={`${styles.SlideContainer} ${styles.clean}`}>
                                        <div className={'image'}>
                                            <Image className={'starsRating'} src={src} alt={title} loading={'lazy'} />
                                        </div>
                                        <div className={'text'}>
                                            <h3>{title}</h3>
                                            <div className={'details'}>
                                                <span className={'title'}>Annualized Return</span>
                                                <p className={'value'}>{returns}</p>
                                            </div>
                                            {entry && (
                                                <div className={'details'}>
                                                    <span className={'title'}>Entry Age</span>
                                                    <p className={'value'}>{entry}</p>
                                                </div>
                                            )}
                                            <div className={'details'}>
                                                <span className={'title'}>Exit Age</span>
                                                <p className={'value'}>{exit}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};
