import React from 'react';
import Slider from 'react-slick';
import StarsRating from '../../../../../assets/img/home/dark/testimonials/stars.svg';
import Image from 'next/image';
import styles from './TestimonialsDarkSection.module.scss';
import { useScrollReveal } from '~/hooks/useScrollReveal';

export const testimonialsSliderData = [
    {
        id: 0,
        description:
            "I dabbled in a few different alternative assets before investing in wine, but this experience has been far more satisfying than anything else I've tried. With Vinovest, I get full portfolio management and a great customer service team.",
        bottomText: 'Jonathan M.'
    },
    {
        id: 1,
        description:
            'Amazing. Great assistance for this first-timer. Beautiful and detailed descriptions for the wine, as well as suggested hold time clearly listed for each holding. Brilliant! I am so glad I found you. Many thanks for all the work done behind the scenes to make it possible.',
        bottomText: 'Theresa T.'
    },
    {
        id: 2,
        description:
            'An exceptional platform for those interested in diversifying their portfolio with fine wine, even with limited prior knowledge of wine investing.',
        bottomText: 'Y.C.'
    },
    {
        id: 3,
        description:
            'Unparalleled, exceptional experience…everything from their interface to their broad personalisation options. I love Vinovest so, so much and totally trust having my portfolio here.',
        bottomText: 'Ashleigh H.'
    }
];

export const TestimonialsDarkSection = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';

    return (
        <div className={`${styles.Wrapper}`} ref={ref}>
            <div className={`${styles.SlickWrapper} ${runAnimation} ${styles.row3}`}>
                <div className={`${styles.full}`}>
                    <Slider
                        className={`${styles.TestimonialsSliderContainer}`}
                        accessibility
                        autoplay={false}
                        slidesToShow={1}
                        slidesToScroll={1}
                        arrows
                        dots
                        responsive={[
                            {
                                breakpoint: 1024,
                                settings: {
                                    slidesToShow: 2,
                                    slidesToScroll: 2,
                                    infinite: true,
                                    dots: true
                                }
                            },
                            {
                                breakpoint: 932,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    infinite: false,
                                    centerMode: false,
                                    arrows: false,
                                    dots: true
                                }
                            },
                            {
                                breakpoint: 429,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    infinite: false,
                                    centerMode: false,
                                    arrows: false,
                                    dots: true
                                }
                            }
                        ]}
                    >
                        {testimonialsSliderData.map(({ id, description, bottomText }) => (
                            <div key={id} className={`${styles.SlideWrapper}`}>
                                <div className={`${styles.SlideContainer} ${styles.clean}`}>
                                    <div className={'message'}>
                                        <Image
                                            className={'starsRating'}
                                            src={StarsRating}
                                            alt={'5 Stars'}
                                            height={'22'}
                                            width={'130'}
                                            loading={'lazy'}
                                        />
                                        <div className={'description'}>"{description}"</div>
                                    </div>
                                    <div className={'profile'}>
                                        <div className={'profileDetails'}>
                                            <p className={'bottomText'}>{bottomText}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};
