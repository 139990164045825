import forbes from '../../../../../assets/img/home/dark/news/forbes.svg';
import cnbc from '../../../../../assets/img/home/dark/news/cnbc.svg';
import wineEnthusiast from '../../../../../assets/img/home/dark/news/wine-enthusiast.svg';
import wsj from '../../../../../assets/img/home/dark/news/wsj.svg';
import bloomberg from '../../../../../assets/img/home/dark/news/bloomberg.svg';
import reuters from '../../../../../assets/img/home/dark/news/reuters.svg';
import wineSpectator from '../../../../../assets/img/home/dark/news/wine-spectator.svg';
import Image from 'next/image';
import React from 'react';
import { useScrollReveal } from '~/hooks/useScrollReveal';
import styles from './AsSeenIn.module.scss';

export const logos = [
    {
        src: forbes,
        alt: 'Forbes - Branding',
        height: 600,
        width: 704,
        id: 0
    },
    {
        src: cnbc,
        alt: 'CNBC - Branding',
        height: 600,
        width: 704,
        id: 0
    },
    {
        src: wineEnthusiast,
        alt: 'Wine Enthusiast - Branding',
        height: 600,
        width: 704,
        id: 0
    },
    {
        src: wsj,
        alt: 'WSJ - Branding',
        height: 600,
        width: 704,
        id: 0
    },
    {
        src: bloomberg,
        alt: 'Bloomberg - Branding',
        height: 600,
        width: 704,
        id: 0
    },
    {
        src: reuters,
        alt: 'Reuters - Branding',
        height: 600,
        width: 704,
        id: 0
    },
    {
        src: wineSpectator,
        alt: 'Wine Spectator - Branding',
        height: 600,
        width: 704,
        id: 0
    }
];

export const AsSeenIn = () => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { hasRevealed } = useScrollReveal({ ref });
    const runAnimation = hasRevealed ? styles.runAnimation : '';

    return (
        <div className={styles.Wrapper} ref={ref}>
            <div className={styles.ImgsWrapper}>
                <div className={`${styles.ImgContainer} ${runAnimation}`} key={'heading'}>
                    <h2>As Seen in:</h2>
                </div>
                {logos.map((data) => (
                    <div className={`${styles.ImgContainer} ${runAnimation}`} id={`${data.id}`} key={data.id}>
                        {data.src && (
                            <Image
                                src={data.src}
                                alt={data.alt}
                                width={data.width}
                                height={data.height}
                                loading={'lazy'}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};
