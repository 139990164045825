import { AsSeenIn } from './components/AsSeenInSection/AsSeenIn';
import { GetStarted } from './components/GetStarted/GetStarted';
import { HeroDark } from './components/HeroSection/HeroSectionDark';
import { HistorySection } from './components/HistorySection/HistorySection';
import { ProductsBenefitsSection } from './components/ProductsBenefitsSection/ProductsBenefitsSection';
import { TestimonialsDarkSection } from './components/TestimonialsSection/TestimonialsDarkSection';
import { TrackRecordSection } from './components/TrackRecordSection/TrackRecordSection';
import styles from './HomePageDark.module.scss';

export const HomePageDark = () => {
    return (
        <div className={styles.container}>
            <HeroDark />
            <AsSeenIn />
            <TrackRecordSection />
            <HistorySection />
            <ProductsBenefitsSection />
            <TestimonialsDarkSection />
            <GetStarted />
        </div>
    );
};
